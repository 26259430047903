import React, { useState } from "react"
import countryList from 'react-select-country-list'
import { navigate } from 'gatsby';
import fetch from 'isomorphic-fetch';

const encode = (data) => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

const Form = ({configuration, closeHandler }) => {

  const [data, setData] = useState({});
  let countries = countryList().getData().map((data) =>
    <option key={data.value} value={data.value}>{data.label}</option>
  );
  const [country, setCountry] = useState('');
  const [shippingCountry, setShippingCountry] = useState('');
  const [shipping, setShipping] = useState(false);

  const handleChange = e => {
    const { name, value } = e.target;
    setData({...data, [`${name}`]: value});
  }


  const handleSubmit = (e) => {
    e.preventDefault();

    const configuredData = {
      cap_color: configuration.map(c => c.capLabel).join(';'),
      disc_color: configuration.map(c => c.discLabel).join(';'),
      finishing: configuration.map(c => c.finishing).join(';'),
      platform: "website",
    };
    const mergedData = {...data, ...configuredData};
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "sample_requests", ...mergedData })
    })
      .then(() => navigate('/thankyou'))
      .catch(error => alert(error));
  };

  return (
    <form onSubmit={handleSubmit} className={`c-form`} name="sample_requests" method={`post`}  data-netlify="true" data-netlify-honeypot="bot-field">

      <input name="bot-field" type={`hidden`} />
      <input name="form-name" type="hidden"  value="sample_requests" />

      <h1>Fill in <strong>your details</strong>, we’ll handle <strong>the rest</strong></h1>

      <div className="c-form__group">

        <div className="c-form__item">
          <input className={`c-form__field`} placeholder={`First name`} type={`text`} id={`first_name`}
                 name={`first_name`} required={true} onChange={handleChange} />
          <label className={`c-form__label`} htmlFor="first_name">First name</label>
        </div>

        <div className="c-form__item">
          <input className={`c-form__field`} placeholder={`Last name`} type={`text`} id={`last_name`} name={`last_name`}
                 required={true} onChange={handleChange}/>
          <label className={`c-form__label`} htmlFor="last_name">Last name</label>
        </div>
      </div>

      <div className="c-form__item">
        <input className={`c-form__field`} placeholder={`Company`} type={`text`} id={`company`} name={`company`}
               required={true} onChange={handleChange}/>
        <label className={`c-form__label`} htmlFor="company">Company</label>
      </div>


      <div className="c-form__item">
        <input className={`c-form__field`} placeholder={`Position (optional)`} type={`text`} id={`position`}
               name={`position`} required={false} onChange={handleChange}/>
        <label className={`c-form__label`} htmlFor="position">Position (optional)</label>
      </div>


      <div className="c-form__item">
        <input className={`c-form__field`} placeholder={`Street`} type={`text`} id={`street`} name={`street`}
               required={true} onChange={handleChange}/>
        <label className={`c-form__label`} htmlFor="street">Street</label>
      </div>

      <div className="c-form__group">

        <div className="c-form__item">
          <input className={`c-form__field`} placeholder={`Zipcode`} type={`text`} id={`zip`} name={`zip`}
                 required={true} onChange={handleChange}/>
          <label className={`c-form__label`} htmlFor="zip">Zipcode</label>
        </div>

        <div className="c-form__item">
          <input className={`c-form__field`} placeholder={`City / State`} type={`text`} id={`city`} name={`city`}
                 required={true} onChange={handleChange}/>
          <label className={`c-form__label`} htmlFor="last_name">City / State</label>
        </div>
      </div>

      <div className="c-form__item">
        <div className="select">
          <select name={`country`} className="select-text" required={true} value={country} onChange={(e) => {setCountry(e.value); handleChange(e);}}>
            <option value={``} disabled={true} />
            {countries}
          </select>
          <span className="select-highlight" />
          <span className="select-bar" />
          <label className="select-label">Select a country</label>
        </div>
      </div>

      <div className="c-form__item">
        <input className={`c-form__field`} placeholder={`E-mail address`} type={`email`} id={`email`} name={`email`}
               required={true} onChange={handleChange}/>
        <label className={`c-form__label`} htmlFor="email">E-mail address</label>
      </div>


      <div className="c-form__item">
        <input className={`c-form__field`} placeholder={`Telephone number (optional)`} type={`text`} id={`telephone`}
               name={`telephone`} required={false} onChange={handleChange}/>
        <label className={`c-form__label`} htmlFor="telephone">Telephone number (optional)</label>
      </div>

      <div className="c-form__item">
      <label><input type="checkbox" onClick={() => setShipping(!shipping) }/> Send the sample to another shipping address</label>
      </div>

      {shipping && (
        <>
          <div className="c-form__item">
            <input className={`c-form__field`} placeholder={`Street`} type={`text`} id={`shipping_street`} name={`shipping_street`}
                   required={true} onChange={handleChange}/>
            <label className={`c-form__label`} htmlFor="street">Street</label>
          </div>

          <div className="c-form__group">

            <div className="c-form__item">
              <input className={`c-form__field`} placeholder={`Zipcode`} type={`text`} id={`shipping_zip`} name={`shipping_zip`}
                     required={true} onChange={handleChange}/>
              <label className={`c-form__label`} htmlFor="shipping_zip">Zipcode</label>
            </div>

            <div className="c-form__item">
              <input className={`c-form__field`} placeholder={`City / State`} type={`text`} id={`shipping_city`} name={`shipping_city`}
                     required={true} onChange={handleChange}/>
              <label className={`c-form__label`} htmlFor="shipping_city">City / State</label>
            </div>
          </div>

          <div className="c-form__item">
            <div className="select">
              <select name={`shipping_country`} className="select-text" required={true} value={shippingCountry} onChange={(e) => {setShippingCountry(e.value); handleChange(e);}}>
                <option value={``} disabled={true} />
                {countries}
              </select>
              <span className="select-highlight" />
              <span className="select-bar" />
              <label className="select-label">Select a country</label>
            </div>
          </div>
        </>
      ) }



      <input type="hidden" name={"shipping_street"} value="" />
      <input type="hidden" name={"shipping_zip"} value="" />
      <input type="hidden" name={"shipping_city"} value="" />
      <input type="hidden" name={"shipping_country"} value="" />

      <input type={`hidden`} name={`disc_color`} value=""/>
      <input type={`hidden`} name={`cap_color`} value=""/>
      <input type={`hidden`} name={`finishing`} value="" />
      <input type={`hidden`} name={`platform`} value="" />

      <button type={`submit`} className={`c-button`}>Place my order</button> or <a href={`#`} onClick={() => closeHandler()}>cancel</a>

    </form>
  )
}


export default Form;
